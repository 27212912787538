import React, { useContext } from 'react';
import { Header, Footer } from 'components/theme';
import { Container } from 'components/common';
import CookieConsent from 'react-cookie-consent';

import {
  ImpressumMainWrapper,
  ImpressumWrapper,
  PrivatKundenWrapperDetails,
  Section5Background,
} from '../components/styles/styles';

export default function About() {

  return (
    <>
      <ImpressumMainWrapper>
        <Header />
        <ImpressumWrapper as={Container}>
          <PrivatKundenWrapperDetails className="positionRelative">
            <div className="boxImpressum"></div>
            <div className="sectionP1">
              <h2 className="dancingScript64">Impressum</h2>
              <p className="latoRegular36Impressum">
                Impressum <br /> Informationspflicht laut §5 E-Commerce Gesetz, §14 Unternehmensgesetzbuch, §63
                Gewerbeordnung und Offenlegungspflicht laut <br /> §25 Mediengesetz. <br /> RD HAUSFEE E.U. <br />{' '}
                Durdica Rašić <br /> Hauptstraße 61/Haus 1/6, <br />
                3021 Pressbaum, <br /> Österreich <br /> Unternehmensgegenstand: Hausbetreuung, bestehend in der
                Durchführung einfacher Reinigungstätigkeiten einschließlich <br /> objektbezogener einfacher
                Wartungstätigkeiten | Personenbetreuung <br /> Firmenbuchnummer: 541732s <br /> Firmensitz: 3021
                Pressbaum, Hauptstraße 61/6n <br /> Tel.: +43 664 166 1589 <br /> E-Mail: office@rd-hausfee.at <br />{' '}
                Berufsrecht: Gewerbeordnung: www.ris.bka.gv.at <br />
                Aufsichtsbehörde/Gewerbebehörde: Bezirkshauptmannschaft St. Pölten <br /> Berufsbezeichnung:
                Hausbetreuer, Personenbetreuer <br /> Verleihungsstaat: Österreich <br /> Quelle: Erstellt mit dem
                Impressum Generator von firmenwebseiten.at
              </p>
              <h2 className="dancingScript64">Datenschutz</h2>
              <p className="latoRegular36Impressum">
                Urheberrechtshinweis <br />
                Alle Inhalte dieser Webseite (Bilder, Fotos, Texte, Videos) unterliegen dem Urheberrecht. Falls
                notwendig, werden wir die unerlaubte Nutzung von Teilen der Inhalte unserer Seite rechtlich verfolgen.
                <br /> EU-Streitschlichtung <br />
                Gemäß Verordnung über Online-Streitbeilegung in Verbraucherangelegenheiten (ODR-Verordnung) möchten wir
                Sie über die Online-Streitbeilegungsplattform (OS-Plattform) informieren. Verbraucher haben die
                Möglichkeit, Beschwerden an die Online Streitbeilegungsplattform der Europäischen Kommission unter
                http://ec.europa.eu/odr?tid=221148109 zu richten. Die dafür notwendigen Kontaktdaten finden Sie oberhalb
                in unserem Impressum. Wir möchten Sie jedoch darauf hinweisen, dass wir nicht bereit oder verpflichtet
                sind, an Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle teilzunehmen.
                <br /> Bildernachweis <br />
                Die Bilder, Fotos und Grafiken auf dieser Webseite sind urheberrechtlich geschützt. Die Bilderrechte
                liegen bei den folgenden Fotografen und Unternehmen: Fotograf Mustermann
                <br /> Haftung für Inhalte dieser Website <br />
                Wir entwickeln die Inhalte dieser Webseite ständig weiter und bemühen uns korrekte und aktuelle
                Informationen bereitzustellen. Leider können wir keine Haftung für die Korrektheit aller Inhalte auf
                dieser Website übernehmen, speziell für jene, die seitens Dritter bereitgestellt wurden. Als
                Diensteanbieter sind wir nicht verpflichtet, die von ihnen übermittelten oder gespeicherten
                Informationen zu überwachen oder nach Umständen zu forschen, die auf eine rechtswidrige Tätigkeit
                hinweisen. Unsere Verpflichtungen zur Entfernung von Informationen oder zur Sperrung der Nutzung von
                Informationen nach den allgemeinen Gesetzen aufgrund von gerichtlichen oder behördlichen Anordnungen
                bleiben auch im Falle unserer Nichtverantwortlichkeit davon unberührt. Sollten Ihnen problematische oder
                rechtswidrige Inhalte auffallen, bitte wir Sie uns umgehend zu kontaktieren, damit wir die
                rechtswidrigen Inhalte entfernen können. Sie finden die Kontaktdaten im Impressum.
                <br /> Haftung für Links auf dieser Webseite <br />
                Unsere Webseite enthält Links zu anderen Webseiten für deren Inhalt wir nicht verantwortlich sind.
                Haftung für verlinkte Websites besteht für uns nicht, da wir keine Kenntnis rechtswidriger Tätigkeiten
                hatten und haben, uns solche Rechtswidrigkeiten auch bisher nicht aufgefallen sind und wir Links sofort
                entfernen würden, wenn uns Rechtswidrigkeiten bekannt werden. Wenn Ihnen rechtswidrige Links auf unserer
                Website auffallen, bitte wir Sie uns zu kontaktieren. Sie finden die Kontaktdaten im Impressum.
                <br /> Einleitung und Überblick <br />
                Wir haben diese Datenschutzerklärung (Fassung 09.09.2021-221148109) verfasst, um Ihnen gemäß der
                Vorgaben der Datenschutz-Grundverordnung (EU) 2016/679 und anwendbaren nationalen Gesetzen zu erklären,
                welche personenbezogenen Daten (kurz Daten) wir als Verantwortliche – und die von uns beauftragten
                Auftragsverarbeiter (z. B. Provider) – verarbeiten, zukünftig verarbeiten werden und welche rechtmäßigen
                Möglichkeiten Sie haben. Die verwendeten Begriffe sind geschlechtsneutral zu verstehen. Kurz gesagt: Wir
                informieren Sie umfassend über Daten, die wir über Sie verarbeiten. Datenschutzerklärungen klingen für
                gewöhnlich sehr technisch und verwenden juristische Fachbegriffe. Diese Datenschutzerklärung soll Ihnen
                hingegen die wichtigsten Dinge so einfach und transparent wie möglich beschreiben. Soweit es der
                Transparenz förderlich ist, werden technische Begriffe leserfreundlich erklärt, Links zu weiterführenden
                Informationen geboten und Grafiken zum Einsatz gebracht. Wir informieren damit in klarer und einfacher
                Sprache, dass wir im Rahmen unserer Geschäftstätigkeiten nur dann personenbezogene Daten verarbeiten,
                wenn eine entsprechende gesetzliche Grundlage gegeben ist. Das ist sicher nicht möglich, wenn man
                möglichst knappe, unklare und juristisch-technische Erklärungen abgibt, so wie sie im Internet oft
                Standard sind, wenn es um Datenschutz geht. Ich hoffe, Sie finden die folgenden Erläuterungen
                interessant und informativ und vielleicht ist die eine oder andere Information dabei, die Sie noch nicht
                kannten. Wenn trotzdem Fragen bleiben, möchten wir Sie bitten, sich an die unten bzw. im Impressum
                genannte verantwortliche Stelle zu wenden, den vorhandenen Links zu folgen und sich weitere
                Informationen auf Drittseiten anzusehen. Unsere Kontaktdaten finden Sie selbstverständlich auch im
                Impressum.
                <br /> Rechtsgrundlagen <br />
                In der folgenden Datenschutzerklärung geben wir Ihnen transparente Informationen zu den rechtlichen
                Grundsätzen und Vorschriften, also den Rechtsgrundlagen der Datenschutz-Grundverordnung, die uns
                ermöglichen, personenbezogene Daten zu verarbeiten. Was das EU-Recht betrifft, beziehen wir uns auf die
                VERORDNUNG (EU) 2016/679 DES EUROPÄISCHEN PARLAMENTS UND DES RATES vom 27. April 2016. Diese
                Datenschutz-Grundverordnung der EU können Sie selbstverständlich online auf EUR-Lex, dem Zugang zum
                EU-Recht, unter https://eur-lex.europa.eu/legal-content/DE/TXT/?uri=celex%3A32016R0679 nachlesen. Wir
                verarbeiten Ihre Daten nur, wenn mindestens eine der folgenden Bedingungen zutrifft: Einwilligung
                (Artikel 6 Absatz 1 lit. a DSGVO): Sie haben uns Ihre Einwilligung gegeben, Daten zu einem bestimmten
                Zweck zu verarbeiten. Ein Beispiel wäre die Speicherung Ihrer eingegebenen Daten eines Kontaktformulars.
                Vertrag (Artikel 6 Absatz 1 lit. b DSGVO): Um einen Vertrag oder vorvertragliche Verpflichtungen mit
                Ihnen zu erfüllen, verarbeiten wir Ihre Daten. Wenn wir zum Beispiel einen Kaufvertrag mit Ihnen
                abschließen, benötigen wir vorab personenbezogene Informationen. Rechtliche Verpflichtung (Artikel 6
                Absatz 1 lit. c DSGVO): Wenn wir einer rechtlichen Verpflichtung unterliegen, verarbeiten wir Ihre
                Daten. Zum Beispiel sind wir gesetzlich verpflichtet Rechnungen für die Buchhaltung aufzuheben. Diese
                enthalten in der Regel personenbezogene Daten. Berechtigte Interessen (Artikel 6 Absatz 1 lit. f DSGVO):
                Im Falle berechtigter Interessen, die Ihre Grundrechte nicht einschränken, behalten wir uns die
                Verarbeitung personenbezogener Daten vor. Wir müssen zum Beispiel gewisse Daten verarbeiten, um unsere
                Website sicher und wirtschaftlich effizient betreiben zu können. Diese Verarbeitung ist somit ein
                berechtigtes Interesse. Weitere Bedingungen wie die Wahrnehmung von Aufnahmen im öffentlichen Interesse
                und Ausübung öffentlicher Gewalt sowie dem Schutz lebenswichtiger Interessen treten bei uns in der Regel
                nicht auf. Soweit eine solche Rechtsgrundlage doch einschlägig sein sollte, wird diese an der
                entsprechenden Stelle ausgewiesen. Zusätzlich zu der EU-Verordnung gelten auch noch nationale Gesetze:
                In Österreich ist dies das Bundesgesetz zum Schutz natürlicher Personen bei der Verarbeitung
                personenbezogener Daten (Datenschutzgesetz), kurz DSG. In Deutschland gilt das Bundesdatenschutzgesetz,
                kurz BDSG. Sofern weitere regionale oder nationale Gesetze zur Anwendung kommen, informieren wir Sie in
                den folgenden Abschnitten darüber. Rechte laut Datenschutzgrundverordnung Laut Artikel 13 DSGVO stehen
                Ihnen die folgenden Rechte zu, damit es zu einer fairen und transparenten Verarbeitung von Daten kommt:
                Sie haben laut Artikel 15 DSGVO ein Auskunftsrecht darüber, ob wir Daten von Ihnen verarbeiten. Sollte
                das zutreffen, haben Sie Recht darauf eine Kopie der Daten zu erhalten und die folgenden Informationen
                zu erfahren: zu welchem Zweck wir die Verarbeitung durchführen; die Kategorien, also die Arten von
                Daten, die verarbeitet werden; wer diese Daten erhält und wenn die Daten an Drittländer übermittelt
                werden, wie die Sicherheit garantiert werden kann; wie lange die Daten gespeichert werden; das Bestehen
                des Rechts auf Berichtigung, Löschung oder Einschränkung der Verarbeitung und dem Widerspruchsrecht
                gegen die Verarbeitung; dass Sie sich bei einer Aufsichtsbehörde beschweren können (Links zu diesen
                Behörden finden Sie weiter unten); die Herkunft der Daten, wenn wir sie nicht bei Ihnen erhoben haben;
                ob Profiling durchgeführt wird, ob also Daten automatisch ausgewertet werden, um zu einem persönlichen
                Profil von Ihnen zu gelangen. Sie haben laut Artikel 16 DSGVO ein Recht auf Berichtigung der Daten, was
                bedeutet, dass wir Daten richtig stellen müssen, falls Sie Fehler finden. Sie haben laut Artikel 17
                DSGVO das Recht auf Löschung („Recht auf Vergessenwerden“), was konkret bedeutet, dass Sie die Löschung
                Ihrer Daten verlangen dürfen. Sie haben laut Artikel 18 DSGVO das Recht auf Einschränkung der
                Verarbeitung, was bedeutet, dass wir die Daten nur mehr speichern dürfen aber nicht weiter verwenden.
                Sie haben laut Artikel 19 DSGVO das Recht auf Datenübertragbarkeit, was bedeutet, dass wir Ihnen auf
                Anfrage Ihre Daten in einem gängigen Format zur Verfügung stellen. Sie haben laut Artikel 21 DSGVO ein
                Widerspruchsrecht, welches nach Durchsetzung eine Änderung der Verarbeitung mit sich bringt. Wenn die
                Verarbeitung Ihrer Daten auf Artikel 6 Abs. 1 lit. e (öffentliches Interesse, Ausübung öffentlicher
                Gewalt) oder Artikel 6 Abs. 1 lit. f (berechtigtes Interesse) basiert, können Sie gegen die Verarbeitung
                Widerspruch einlegen. Wir prüfen danach so rasch wie möglich, ob wir diesem Widerspruch rechtlich
                nachkommen können. Werden Daten verwendet, um Direktwerbung zu betreiben, können Sie jederzeit gegen
                diese Art der Datenverarbeitung widersprechen. Wir dürfen Ihre Daten danach nicht mehr für
                Direktmarketing verwenden. Werden Daten verwendet, um Profiling zu betreiben, können Sie jederzeit gegen
                diese Art der Datenverarbeitung widersprechen. Wir dürfen Ihre Daten danach nicht mehr für Profiling
                verwenden. Sie haben laut Artikel 22 DSGVO unter Umständen das Recht, nicht einer ausschließlich auf
                einer automatisierten Verarbeitung (zum Beispiel Profiling) beruhenden Entscheidung unterworfen zu
                werden. Wenn Sie glauben, dass die Verarbeitung Ihrer Daten gegen das Datenschutzrecht verstößt oder
                Ihre datenschutzrechtlichen Ansprüche in sonst einer Weise verletzt worden sind, können Sie sich bei der
                Aufsichtsbehörde beschweren. Diese ist für Österreich die Datenschutzbehörde, deren Website Sie unter
                https://www.dsb.gv.at/ finden und für Deutschland können Sie sich an die Bundesbeauftragte für den
                Datenschutz und die Informationsfreiheit (BfDI) wenden. Kurz gesagt: Sie haben Rechte – zögern Sie
                nicht, die oben gelistete verantwortliche Stelle bei uns zu kontaktieren!
                <br /> Kommunikation <br />
                Wenn Sie mit uns Kontakt aufnehmen und per Telefon, E-Mail oder Online-Formular kommunizieren, kann es
                zur Verarbeitung personenbezogener Daten kommen. Die Daten werden für die Abwicklung und Bearbeitung
                Ihrer Frage und des damit zusammenhängenden Geschäftsvorgangs verarbeitet. Die Daten während ebensolange
                gespeichert bzw. so lange es das Gesetz vorschreibt.
                <br /> Betroffene Personen <br />
                Von den genannten Vorgängen sind alle betroffen, die über die von uns bereit gestellten
                Kommunikationswege den Kontakt zu uns suchen.
                <br /> Telefon <br />
                Wenn Sie uns anrufen, werden die Anrufdaten auf dem jeweiligen Endgerät und beim eingesetzten
                Telekommunikationsanbieter pseudonymisiert gespeichert. Außerdem können Daten wie Name und Telefonnummer
                im Anschluss per E-Mail versendet und zur Anfragebeantwortung gespeichert werden. Die Daten werden
                gelöscht, sobald der Geschäftsfall beendet wurde und es gesetzliche Vorgaben erlauben.
                <br /> E-Mail <br />
                Wenn Sie mit uns per E-Mail kommunizieren, werden Daten gegebenenfalls auf dem jeweiligen Endgerät
                (Computer, Laptop, Smartphone,...) gespeichert und es kommt zur Speicherung von Daten auf dem
                E-Mail-Server. Die Daten werden gelöscht, sobald der Geschäftsfall beendet wurde und es gesetzliche
                Vorgaben erlauben.
                <br /> Online Formulare <br />
                Wenn Sie mit uns mittels Online-Formular kommunizieren, werden Daten auf unserem Webserver gespeichert
                und gegebenenfalls an eine E-Mail-Adresse von uns weitergeleitet. Die Daten werden gelöscht, sobald der
                Geschäftsfall beendet wurde und es gesetzliche Vorgaben erlauben.
                <br /> Rechtsgrundlagen <br />
                Die Verarbeitung der Daten basiert auf den folgenden Rechtsgrundlagen: Art. 6 Abs. 1 lit. a DSGVO
                (Einwilligung): Sie geben uns die Einwilligung Ihre Daten zu speichern und weiter für den Geschäftsfall
                betreffende Zwecke zu verwenden; Art. 6 Abs. 1 lit. b DSGVO (Vertrag): Es besteht die Notwendigkeit für
                die Erfüllung eines Vertrags mit Ihnen oder einem Auftragsverarbeiter wie z. B. dem Telefonanbieter oder
                wir müssen die Daten für vorvertragliche Tätigkeiten, wie z. B. die Vorbereitung eines Angebots,
                verarbeiten; Art. 6 Abs. 1 lit. f DSGVO (Berechtigte Interessen): Wir wollen Kundenanfragen und
                geschäftliche Kommunikation in einem professionellen Rahmen betreiben. Dazu sind gewisse technische
                Einrichtungen wie z. B. E-Mail-Programme, Exchange-Server und Mobilfunkbetreiber notwendig, um die
                Kommunikation effizient betreiben zu können.
                <br /> Datenübertragung in Drittländer <br />
                Wir übertragen oder verarbeiten Daten nur dann in Länder außerhalb der EU (Drittländer), wenn Sie dieser
                Verarbeitung zustimmen, dies gesetzlich vorgeschrieben ist oder vertraglich notwendig und in jedem Fall
                nur soweit dies generell erlaubt ist. Ihre Zustimmung ist in den meisten Fällen der wichtigste Grund,
                dass wir Daten in Drittländern verarbeiten lassen. Die Verarbeitung personenbezogener Daten in
                Drittländern wie den USA, wo viele Softwarehersteller Dienstleistungen anbieten und Ihre Serverstandorte
                haben, kann bedeuten, dass personenbezogene Daten auf unerwartete Weise verarbeitet und gespeichert
                werden. Nach Möglichkeit versuchen wir Serverstandorte innerhalb der EU zu nutzen, sofern das angeboten
                wird. Wir informieren Sie an den passenden Stellen dieser Datenschutzerklärung genauer über
                Datenübertragung in Drittländer, sofern diese zutrifft.
                <br /> Sicherheit der Datenverarbeitung <br />
                Um personenbezogene Daten zu schützen, haben wir sowohl technische als auch organisatorische Maßnahmen
                umgesetzt. Wo es uns möglich ist, verschlüsseln oder pseudonymisieren wir personenbezogene Daten.
                Dadurch machen wir es im Rahmen unserer Möglichkeiten so schwer wie möglich, dass Dritte aus unseren
                Daten auf persönliche Informationen schließen können. Art. 25 DSGVO spricht hier von “Datenschutz durch
                Technikgestaltung und durch datenschutzfreundliche Voreinstellungen” und meint damit, dass man sowohl
                bei Software (z. B. Formularen) also auch Hardware (z. B. Zugang zum Serverraum) immer an Sicherheit
                denkt und entsprechende Maßnahmen setzt. Im Folgenden gehen wir, falls erforderlich, noch auf konkrete
                Maßnahmen ein. <br /> Quelle: Erstellt mit dem Datenschutz Generator von firmenwebseiten.at
              </p>
            </div>
          </PrivatKundenWrapperDetails>
        </ImpressumWrapper>
        <Section5Background>
          <Footer />
        </Section5Background>
      </ImpressumMainWrapper>
      <CookieConsent
        location="bottom"
        buttonText="Ich stimme zu"
        cookieName="myAwesomeCookieName2"
        style={{ background: '#95c357' }}
        buttonStyle={{ color: '#95c357', fontSize: '13px', background: '#fff' }}
      >
        Diese Website verwendet Cookies – nähere Informationen dazu und zu Ihren Rechten als Benutzer finden Sie in
        unserer <a href="/impressum">Datenschutzerklärung</a> am Ende der Seite. Klicken Sie auf „Ich stimme zu“, um
        Cookies zu akzeptieren.
      </CookieConsent>
    </>
  );
}
